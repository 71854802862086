<template>
  <div class="checkupdetail">
    <div class="card-box">
      <div class="title">
        <span>报告单号：</span>
        <span>{{ bgdh }}</span>
      </div>
      <div class="row mb24">
        <div class="left">
          <span>姓名：</span>
          <span>{{ data.xm }}</span>
        </div>
        <div>
          <span>性别：</span>
          <span>{{ data.xb }}</span>
        </div>
      </div>
      <div class="row mb24">
        <div class="left">
          <span>年龄：</span>
          <span>{{ data.age }}</span>
        </div>
        <div>
          <span>科室：</span>
          <span>{{ data.kb }}</span>
        </div>
      </div>
      <div class="row mb24">
        <span>就诊卡号：</span>
        <span>{{ data.jzkh }}</span>
      </div>
      <div class="row mb24">
        <div class="left">
          <span>病区：</span>
          <span />
        </div>
        <div>
          <span>床号：</span>
          <span />
        </div>
      </div>
      <div class="row mb24">
        <div class="left">
          <span>申请医师：</span>
          <span>{{ data.sqys }}</span>
        </div>
        <div>
          <span>检查医师：</span>
          <span>{{ data.shysxm }}</span>
        </div>
      </div>
      <div class="row mb24">
        <span>检查日期：</span>
        <span>{{ data.jcrq && data.jcrq.split(' ')[0] }}</span>
      </div>
      <div class="row">
        <span>报告医师：</span>
        <span>{{ data.bgysxm }}</span>
      </div>
    </div>
    <div v-for="item in data.listJcReport" :key="item.bbdh" class="card-box">
      <div class="title">
        {{ item.jclb }}:
      </div>
      <div class="row">
        {{ item.jg }}
      </div>
    </div>
  </div>
</template>

<script>
import {patientModel} from "../../../api/patient"
export default {
  name:'CheckupDetail',
  data() {
    return {
      data:{},
      bgdh:this.$route.query.bgdh
    }
  },
  mounted(){
    this.loadData()
  },
  methods:{
    loadData(){
      let params={
        // patid: this.$route.query.patid ||'',
        bgdh: this.$route.query.bgdh ||'',
        patxm: this.$route.query.patxm ||'',
        zjh: this.$route.query.zjh ||''
      }
      patientModel.jcReportDetail(params).then(res=>{
        this.data = res.data
      })
    }
  }
}
</script>

<style scoped lang='less'>
@import "../../../assets/styles/variable.less";

.checkupdetail {
    padding: 32px;

    .card-box {
        width: 100%;
        background: #FFFFFF;
        border-radius: 12px;
        margin-bottom: 24px;
        padding: 32px;
        box-sizing: border-box;

        .title {
            font-size: 32px;
            font-weight: bold;
            color: #3D3D3D;
            line-height: 45px;
            margin-bottom: 32px;
        }

        .row {
            display: flex;
            line-height: 40px;
            font-size: 28px;
            // margin-bottom: 24px;
            color: #666666;

            .left {
                text-align: left;
                width: 325px;
            }
        }

        .mb24 {
            margin-bottom: 24px;
        }
    }
}
</style>
